import { io } from 'socket.io-client'

/**
 * Класс для работы с вебсокетами
 */
export default class SocketIO {
  constructor() {
    if (SocketIO._instance) {
      return SocketIO._instance
    }
    const wsHost = `${process.env.REACT_APP_WEBSOCKET_URL}`
    this.socket = io(wsHost, {
      transports: ['websocket'],
    })
    SocketIO._instance = this
  }

  /**
   * Устанавливаем внешний код пользователя
   * @param externalId
   */
  setExternalId(externalId) {
    this.socket.emit('set-external-id', externalId)
  }

  /**
   * Обработчик для переподключения
   * @param callback
   */
  onReconnect(callback = () => {}) {
    this.socket.io.on('reconnect', (attempt) => {
      callback()
    })
  }

  /**
   * Присоединение к комнате
   * @param {string} roomId
   */
  join(roomId) {
    this.socket.emit('join', roomId)
  }

  /**
   * Покинуть комнату
   * @param {string} roomId
   */
  leave(roomId) {
    this.socket.emit('leave', roomId)
  }

  /**
   * Прослушка события для текущего соединения
   * @param {string} event
   * @param callback
   */
  on(event, callback) {
    this.socket.on(event, callback)
  }

  /**
   * Отмена прослушки события для текущего соединения
   * @param {string} event
   * @param callback
   */
  off(event, callback) {
    this.socket.off(event, callback)
  }

  /**
   * Отключение от сокета
   */
  disconnect() {
    this.socket.disconnect()
  }

  /**
   * Прослушка нескольких событий разом
   * @param {string[]} events
   * @param {function} callback
   */
  onEvents(events, callback) {
    events.forEach((event) => {
      this.on(event, callback.bind(null, event))
    })
  }

  /**
   * Отмена прослушки нескольких событий разом
   */
  offEvents(events) {
    events.forEach((event) => {
      this.socket.off(event)
    })
  }
}

import {
  SET_BACKEND_ERROR,
  SET_BACKEND_FILE_ERROR,
  SET_BACKEND_VALIDATION_ERROR,
} from './backendErrorsTypes'

const initialState = {
  backendErrors: [],
  validationErrors: [],
  fileErrors: [],
}

const backendErrorsReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_BACKEND_ERROR:
      return { ...state, backendErrors: [...state.backendErrors, rest] }
    case SET_BACKEND_VALIDATION_ERROR:
      return { ...state, validationErrors: [...state.validationErrors, rest] }
    case SET_BACKEND_FILE_ERROR:
      return { ...state, fileErrors: [...state.fileErrors, rest] }
    default:
      return state
  }
}
export default backendErrorsReducer

const {
  GET_RECIPIENTS,
  GET_RECIPIENT_BY_ID,
  CLEAR_RECIPIENTS,
  CLEAR_RECIPIENT,
} = require('./recipientsReasonsFailureTypes')

const initialState = {
  recipients: [],
  recipient: null,
}

const recipientsReasonsFailureReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_RECIPIENTS:
      return {
        ...prevState,
        recipients: action.data,
      }
    case GET_RECIPIENT_BY_ID:
      return {
        ...prevState,
        recipient: action.data,
      }
    case CLEAR_RECIPIENTS:
      return {
        ...prevState,
        recipients: [],
      }
    case CLEAR_RECIPIENT:
      return {
        ...prevState,
        recipient: null,
      }
    default:
      return prevState
  }
}

export default recipientsReasonsFailureReducer

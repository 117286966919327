import {
  GET_STIPEND,
  GET_STIPEND_FACULTIES,
  GET_STIPEND_GROUPS,
  GET_STIPEND_PREPARATION_TYPES,
} from './stipendTypes'

const initialState = {
  stipend: {},
  studentGroups: {},
}

const stipendReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_STIPEND:
      return {
        ...prevState,
        stipend: { ...action.data.data },
      }
    case GET_STIPEND_GROUPS:
      return {
        ...prevState,
        studentGroups: { ...action.data.data },
      }
    case GET_STIPEND_FACULTIES:
      return {
        ...prevState,
        faculties: { ...action.data.data },
      }
    case GET_STIPEND_PREPARATION_TYPES:
      return {
        ...prevState,
        preparationTypes: { ...action.data.data },
      }
    default:
      return prevState
  }
}

export default stipendReducer

import React from 'react'
import AppPreloader from './ui/AppPreloader'
import { useSelector } from 'react-redux'
const AppLoading = () => {
  const loading = useSelector((state) => state.loading)

  if (!loading.show) {
    return null
  }

  return <AppPreloader full hidden={false} />
}

export default AppLoading

export const setSessionToast = (message, type = 'success') => {
  sessionStorage.setItem(
    'toast',
    JSON.stringify({
      message: message,
      type: type,
    }),
  )
}

export const getSessionToast = () => {
  return JSON.parse(sessionStorage.getItem('toast'))
}

export const clearSessionToast = () => {
  sessionStorage.removeItem('toast')
}

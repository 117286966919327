import { GET_COUNTERS, READ_NOTIFICATION, SET_ACCESS } from './notificationTypes'

const initialState = {
  read: {},
  counters: {
    announcement: {
      icon: 'chat',
      link: '/announcement',
      counter: 0,
      access: false,
    },
    appeal: {
      icon: 'message',
      link: '/appeal',
      counter: 0,
      access: false,
    },
    cert_order: {
      icon: 'attach',
      link: '/reference_order',
      counter: 0,
      access: false,
    },
    homework: {
      icon: 'bookmark',
      link: '/homework',
      counter: 0,
      access: false,
    },
    users: {
      icon: 'users',
      link: '/users',
      counter: 0,
      access: false,
    },
    headmans: {
      icon: 'user-check',
      link: '/headmans',
      counter: 0,
      access: false,
    },
    lessons: {
      icon: 'calendar',
      link: '/schedule',
      counter: 0,
      access: false,
    },
    payslips: {
      icon: 'wallet',
      link: '/pay_slips',
      counter: 0,
      access: false,
    },
    grants: {
      icon: 'wallet',
      link: '/stipend',
      counter: 0,
      access: false,
    },
    electronic_journal: {
      icon: 'journal',
      link: '/electronic_journal',
      counter: 0,
      access: false,
    },
    absences: {
      icon: 'progress-up',
      link: '/reasons_failure',
      counter: 0,
      access: false,
    },
    // системные поля для пользователей (отдельно не отображаются)
    users_binds: {
      counter: 0,
      access: false,
    },
    users_without_group: {
      counter: 0,
      access: false,
    },
    users_without_main_provider_system: {
      counter: 0,
      access: false,
    },
  },
}

/**
 * Хранилище для уведомлений
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const notificationReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_COUNTERS:
      let newCounters = { ...prevState.counters }
      Object.entries(action.data).forEach(([key, count]) => {
        if (key in newCounters) {
          newCounters[key].counter = Number(count)
        }
      })
      newCounters['users'].counter =
        (Number(action.data?.users_binds) || 0) +
        (Number(action.data?.users_without_group) || 0) +
        (Number(action.data?.users_without_main_provider_system) || 0)
      return { ...prevState, counters: newCounters }
    case READ_NOTIFICATION:
      return { ...prevState, read: action.data }
    case SET_ACCESS:
      const counters = { ...prevState.counters }
      action.data.forEach((item) => {
        if (item.slug in counters) {
          counters[item.slug].access = true
        }
      })
      return { ...prevState, counters: counters }
    default:
      return prevState
  }
}

export default notificationReducer

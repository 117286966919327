import {
  CLEAR_HEADMAN,
  CLEAR_HEADMANS,
  GET_HEADMANS,
  GET_HEADMAN_BY_ID,
  GET_USERS,
  HEADMAN_WAS_UPDATED,
  GET_HEADMANS_FILTERS,
} from './headmansTypes'

const initialState = {
  headmans: {},
  headman: null,
  users: [],
  headmanWasUpdated: false,
  filters: {
    course_ids: [],
    faculty_external_ids: [],
    grade_external_ids: [],
    study_groups: [],
  },
}

/**
 * Хранилище для старост
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const headmansReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_HEADMANS:
      return {
        ...prevState,
        headmans: action.data,
        headmanWasUpdated: false,
      }
    case GET_HEADMAN_BY_ID:
      return {
        ...prevState,
        headman: action.data,
        headmanWasUpdated: false,
      }
    case GET_USERS:
      return {
        ...prevState,
        users: action.data,
      }
    case GET_HEADMANS_FILTERS:
      return {
        ...prevState,
        filters: action.data,
      }
    case HEADMAN_WAS_UPDATED:
      return {
        ...prevState,
        headmanWasUpdated: true,
      }
    case CLEAR_HEADMAN:
      return {
        ...prevState,
        headman: null,
      }
    case CLEAR_HEADMANS:
      return {
        ...prevState,
        headmans: [],
      }
    default:
      return prevState
  }
}

export default headmansReducer

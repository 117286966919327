import { GET_ALL_INFO } from './infoTypes'

const initialState = {
  items: {},
}

const infoReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INFO:
      return { ...prevState, items: action.data }
    default:
      return prevState
  }
}

export default infoReducer

import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
})

export const apiSetHeader = (name, value) => {
  api.defaults.headers[name] = value
}

export const apiSetToken = (token) => {
  apiSetHeader('Authorization', `Bearer ${token}`)
}

export const apiSetCurrentUserGroup = (group) => {
  apiSetHeader('Current-UserGroup', `${group}`)
}

api.getAbortController = () => {
  return new AbortController()
}

function onGetCall(config) {
  return config.method === 'get'
}

api.interceptors.request.use(
  function (config) {
    if (config.params) {
      config.params = Object.fromEntries(
        Object.entries(config.params).filter(([_, v]) => {
          if (typeof v === 'string') {
            if (v.includes('NaN')) {
              return false
            }
            return v !== ''
          }
          return true
        }),
      )
    }
    return config
  },
  null,
  { runWhen: onGetCall },
)

export default api

export const mockAllHomeWorkData = {
  result: {
    data: [
      {
        id: 1,
        hometask: {
          id: 3,
          discipline: {
            id: 3,
            name: 'Физика',
          },
          description:
            '<p>Необходимо ознакомиться с дополнительными материалами и составить свое видение о сложности бытия.</p>',
          author_profile: {
            id: 3,
            name: 'Иван',
            surname: 'Абрамов',
          },
          solution: {
            text: 'Решение домашней работы',
            attachment: [
              {
                id: 1,
                name: 'file1.jpg',
                type: 'file',
              },
            ],
            date: '02.10.2022',
          },
          files: [],
          links: [],
          active: true,
          deadline: '2022-05-22T00:00:00.000Z',
          created_at: '2022-05-20T00:00:00.000Z',
        },
        respondent_profile: {
          id: 4,
          group: '213214',
          course: 2,
        },
        status: {
          id: 6,
          name: 'Выполнена',
          active: true,
          code: 'done',
          created_at: null,
          updated_at: null,
          colour: '#0000FF',
        },
        score: null,
        comment: null,
        departure_date: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
      {
        id: 2,
        hometask: {
          id: 3,
          discipline: {
            id: 3,
            name: 'Философия',
          },
          author_profile: {
            id: 3,
            name: 'Иван',
            surname: 'Ермолов',
          },
          description:
            '<p>Необходимо ознакомиться с дополнительными материалами и составить свое видение о сложности бытия.</p>',
          files: [
            {
              id: 1,
              name: 'file1.jpg',
              type: 'file',
            },
          ],
          links: [
            {
              id: 2,
              name: 'https://www.youtube.com/',
              type: 'link',
            },
          ],
          active: true,
          deadline: '2022-05-30T00:00:00.000Z',
          created_at: '2022-05-20T00:00:00.000Z',
        },
        respondent_profile: {
          id: 4,
          group: '213214',
          course: 2,
        },
        status: {
          id: 5,
          name: 'Новое',
          active: true,
          code: 'new',
          created_at: null,
          updated_at: null,
          colour: '#808080',
        },
        score: null,
        comment: null,
        departure_date: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
      {
        id: 3,
        hometask: {
          id: 3,
          discipline: {
            id: 3,
            name: 'Математика',
          },
          author_profile: {
            id: 3,
            name: 'Иван',
            surname: 'Сидоров',
          },
          description:
            '<p>Необходимо ознакомиться с дополнительными материалами и составить свое видение о сложности бытия.</p>',
          files: [
            {
              id: 1,
              name: 'file1.jpg',
              type: 'file',
            },
          ],
          links: [
            {
              id: 2,
              name: 'https://www.youtube.com/',
              type: 'link',
            },
          ],
          active: true,
          deadline: '2022-03-02T00:00:00.000Z',
          created_at: '2022-05-20T00:00:00.000Z',
        },
        respondent_profile: {
          id: 4,
          group: '213214',
          course: 2,
        },
        status: {
          id: 5,
          name: 'Новое',
          active: true,
          code: 'new',
          created_at: null,
          updated_at: null,
          colour: '#808080',
        },
        score: null,
        comment: null,
        departure_date: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
      {
        id: 4,
        hometask: {
          id: 3,
          discipline: {
            id: 3,
            name: 'Астрофизика',
          },
          author_profile: {
            id: 3,
            name: 'Иван',
            surname: 'Петров',
          },
          description:
            '<p>Необходимо ознакомиться с дополнительными материалами и составить свое видение о сложности бытия.</p>',
          files: [
            {
              id: 1,
              name: 'file1.jpg',
              type: 'file',
            },
          ],
          links: [
            {
              id: 2,
              name: 'https://www.youtube.com/',
              type: 'link',
            },
          ],
          solution: {
            text: 'Решение домашней работы',
            attachment: [],
            date: '02.10.2022',
          },
          active: true,
          deadline: '2022-09-20T00:00:00.000Z',
          created_at: '2022-05-20T00:00:00.000Z',
        },
        respondent_profile: {
          id: 4,
          group: '213214',
          course: 2,
        },
        status: {
          id: 7,
          name: 'На проверке',
          active: true,
          code: 'checking',
          created_at: null,
          updated_at: null,
          colour: '#FFFF00',
        },
        score: null,
        comment: null,
        departure_date: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
      {
        id: 5,
        hometask: {
          id: 3,
          discipline: {
            id: 3,
            name: 'Социология',
          },
          author_profile: {
            id: 3,
            name: 'Иван',
            surname: 'Иванов',
          },
          solution: {
            text: 'Решение домашней работы',
            attachment: [
              {
                id: 1,
                name: 'file1.jpg',
                type: 'file',
              },
            ],
            date: '02.10.2022',
          },
          description:
            '<p>Необходимо ознакомиться с дополнительными материалами и составить свое видение о сложности бытия.</p>',
          files: [
            {
              id: 1,
              name: 'file1.jpg',
              type: 'file',
            },
          ],
          links: [
            {
              id: 2,
              name: 'https://www.youtube.com/',
              type: 'link',
            },
          ],
          active: true,
          deadline: '2022-05-20T00:00:00.000Z',
          created_at: '2022-05-20T00:00:00.000Z',
        },
        respondent_profile: {
          id: 4,
          group: '213214',
          course: 2,
        },
        status: {
          id: 8,
          name: 'Проверена',
          active: true,
          code: 'checked',
          created_at: null,
          updated_at: null,
          colour: '#008000',
        },
        score: 5,
        comment: 'Отличная работа',
        departure_date: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
    ],
    current_page: 1,
    last_page: 5,
    per_page: 20,
    total: 100,
  },
}
export const mockAllHomeTaskData = {
  result: {
    data: [
      {
        id: 1,
        profile_id: 2,
        discipline: {
          id: 3,
          name: 'Дисциплина 3',
        },
        name: 'Домашнее задание 1',
        description: 'Описание домашнего задания',
        studentGroups: ['213214', '223435'],
        course: 2,
        active: true,
        deadline: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
        tableData: [
          {
            id: 1,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 7,
              name: 'На проверке',
              active: true,
              code: 'checking',
              created_at: null,
              updated_at: null,
              colour: '#FFFF00',
            },
            score: 5,
            comment: null,
          },
          {
            id: 3,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 8,
              name: 'Проверена',
              active: true,
              code: 'checked',
              created_at: null,
              updated_at: null,
              colour: '#008000',
            },
            score: 5,
            comment: 'Отличная работа',
          },
        ],
        metrics: {
          total: 100,
          checked: 10,
          for_checking: 80,
          not_completed: 10,
        },
      },
      {
        id: 2,
        profile_id: 2,
        discipline: {
          id: 3,
          name: 'Дисциплина 3',
        },
        name: 'Домашнее задание 1',
        description: 'Описание домашнего задания',
        studentGroups: ['213214', '223435'],
        course: 2,
        active: true,
        tableData: [
          {
            id: 1,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 7,
              name: 'На проверке',
              active: true,
              code: 'checking',
              created_at: null,
              updated_at: null,
              colour: '#FFFF00',
            },
            score: 5,
            comment: null,
          },
          {
            id: 3,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 8,
              name: 'Проверена',
              active: true,
              code: 'checked',
              created_at: null,
              updated_at: null,
              colour: '#008000',
            },
            score: 5,
            comment: 'Отличная работа',
          },
        ],
        deadline: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
        metrics: {
          total: 100,
          checked: 10,
          for_checking: 80,
          not_completed: 10,
        },
      },
      {
        id: 3,
        profile_id: 2,
        discipline: {
          id: 3,
          name: 'Дисциплина 3',
        },
        name: 'Домашнее задание 1',
        description: 'Описание домашнего задания',
        studentGroups: ['213214', '223435'],
        course: 2,
        active: true,
        tableData: [
          {
            id: 1,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 7,
              name: 'На проверке',
              active: true,
              code: 'checking',
              created_at: null,
              updated_at: null,
              colour: '#FFFF00',
            },
            score: 5,
            comment: null,
          },
          {
            id: 3,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 8,
              name: 'Проверена',
              active: true,
              code: 'checked',
              created_at: null,
              updated_at: null,
              colour: '#008000',
            },
            score: 5,
            comment: 'Отличная работа',
          },
        ],
        deadline: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
        metrics: {
          total: 100,
          checked: 10,
          for_checking: 80,
          not_completed: 10,
        },
      },
      {
        id: 4,
        profile_id: 2,
        discipline: {
          id: 3,
          name: 'Дисциплина 3',
        },
        name: 'Домашнее задание 1',
        description: 'Описание домашнего задания',
        studentGroups: ['213214', '223435'],
        course: 2,
        active: true,
        tableData: [
          {
            id: 1,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 7,
              name: 'На проверке',
              active: true,
              code: 'checking',
              created_at: null,
              updated_at: null,
              colour: '#FFFF00',
            },
            score: 5,
            comment: null,
          },
          {
            id: 3,
            student: { id: 1, name: 'Имя Студента' },
            sending_date: '2022-05-20T00:00:00.000Z',
            status: {
              id: 8,
              name: 'Проверена',
              active: true,
              code: 'checked',
              created_at: null,
              updated_at: null,
              colour: '#008000',
            },
            score: 5,
            comment: 'Отличная работа',
          },
        ],
        deadline: '2022-05-20T00:00:00.000Z',
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
        metrics: {
          total: 100,
          checked: 10,
          for_checking: 80,
          not_completed: 10,
        },
      },
    ],
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 4,
  },
}
export const mockAllHandbookData = {
  result: {
    classroomLocations: [
      {
        id: 1,
        name: 'Не задано',
        active: true,
        created_at: null,
      },
      {
        id: 2,
        name: 'Северное крыло',
        active: true,
        created_at: null,
      },
      {
        id: 3,
        name: 'Южное крыло',
        active: true,
        created_at: null,
      },
      {
        id: 4,
        name: '123',
        active: true,
        created_at: '2022-08-03T13:04:03.000Z',
      },
    ],
    course: [
      {
        id: 1,
        name: '1',
      },
      {
        id: 2,
        name: '2',
      },
      {
        id: 3,
        name: '3',
      },
      {
        id: 4,
        name: '4',
      },
      {
        id: 5,
        name: '5',
      },
    ],
    profile: [
      { name: 'Профиль1', id: '59' },
      { name: 'Профиль2', id: '123' },
      { name: 'Профиль3', id: '69' },
    ],
    teacher: [
      { name: 'И.П. Иванов', id: '1' },
      { name: 'Иванов Иван', id: '32' },
      { name: 'Петров Иван', id: '42' },
      { name: 'Сидоров Иван', id: '60' },
    ],
    status: [
      {
        id: 5,
        name: 'Новое',
        active: true,
        code: 'new',
        created_at: null,
        updated_at: null,
        colour: '#808080',
      },
      {
        id: 6,
        name: 'Выполнена',
        active: true,
        code: 'done',
        created_at: null,
        updated_at: null,
        colour: '#0000FF',
      },
      {
        id: 7,
        name: 'На проверке',
        active: true,
        code: 'checking',
        created_at: null,
        updated_at: null,
        colour: '#FFFF00',
      },
      {
        id: 8,
        name: 'Проверена',
        active: true,
        code: 'checked',
        created_at: null,
        updated_at: null,
        colour: '#008000',
      },
    ],
    faculty: [
      { name: 'Физический', id: 1 },
      { name: 'Математический', id: 2 },
      { name: 'Лингвистический', id: 3 },
      { name: 'Филологический', id: 4 },
    ],
    theme: [
      { name: 'Поступление', id: 1 },
      { name: 'Отчисление', id: 2 },
      { name: 'Долги', id: 3 },
      { name: 'Пропуски', id: 4 },
    ],
  },
}
export const mockAdditionalSettingData = {
  result: {
    current_page: 1,
    data: [
      {
        id: 15,
        parent_category_id: 14,
        name: 'Получатель обращений по умолчанию',
        slug: 'poluchatel_obrashhenij_po_umolchaniyu',
        created_at: null,
        updated_at: null,
      },
      {
        id: 16,
        parent_category_id: 14,
        name: 'Получатель заказов на справки по умолчанию',
        slug: 'poluchatel_zakazov_na_spravki_po_umolchaniyu',
        created_at: null,
        updated_at: null,
      },
    ],
    last_page: 1,
    per_page: 10,
    total: 2,
  },
}
export const mockRecipientsSettingData = {
  result: {
    current_page: 1,
    data: [
      {
        id: 21,
        parent_category_id: 20,
        slug: 'zakaz-spravok',
        name: 'Заказ справок',
        created_at: null,
        updated_at: null,
      },
      {
        id: 22,
        parent_category_id: 20,
        slug: 'obrashheniya',
        name: 'Обращения',
        created_at: null,
        updated_at: null,
      },
    ],
    last_page: 1,
    per_page: 10,
    total: 2,
  },
}
export const mockSettingData = [
  {
    id: 105,
    category_id: 15,
    slug: 'poluchatel_obrashhenij_po_umolchaniyu',
    tag: 'additional_settings_user',
    name: 'Получатель обращений по умолчанию',
    data: {
      type: 'integer',
      value: 10,
    },
    created_at: null,
    updated_at: null,
  },
  {
    id: 106,
    category_id: 16,
    slug: 'poluchatel_zakazov_na_spravki_po_umolchaniyu',
    tag: 'additional_settings_user',
    name: 'Получатель заказов на справки по умолчанию',
    data: {
      type: 'integer',
      value: null,
    },
    created_at: null,
    updated_at: null,
  },
]
export const mockScheduleData = {
  result: {
    data: [
      {
        id: 1,
        date: '2022-09-03',
        time_from: '09:00',
        time_to: '10:35',
        discipline_id: 1,
        conducting_way_id: 4,
        lesson_type_id: 1,
        classroom_id: 1,
        teachers: [
          {
            id: 1,
            external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
            last_name: 'Иванов',
            first_name: 'Иван',
            middle_name: 'Петрович',
            name: 'И.П. Иванов',
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        study_groups: [
          {
            id: 1,
            name: '2207-НД',
            base_groups: [0],
            members: [1],
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        closed: false,
        marked: false,
        room_not_created: true,
        online_platform_room_id: null,
        discipline: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          name: 'Алгебра',
          active: true,
          department_id: 21,
          location_id: 12,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        conduction_way: {
          id: 4,
          name: 'Очное',
          active: true,
          created_at: null,
          slug: 'intramural',
          updated_at: null,
        },
        lesson_type: {
          id: 3,
          name: 'Практикум',
          active: true,
          created_at: null,
          colour: '#B9EABB',
        },
        classroom: {
          id: 1,
          type_id: 1,
          department_id: 1,
          floor_id: 1,
          block_id: 1,
          location_id: 1,
          students_amount: 100,
          active: true,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        online_platform_room: {},
        lesson_numbers: [1, 2],
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
      {
        id: 2,
        date: '2022-09-04',
        time_from: '09:00',
        time_to: '10:35',
        discipline_id: 1,
        conducting_way_id: 5,
        lesson_type_id: 1,
        classroom_id: 1,
        teachers: [
          {
            id: 1,
            external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
            last_name: 'Иванов',
            first_name: 'Иван',
            middle_name: 'Петрович',
            name: 'И.П. Иванов',
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        study_groups: [
          {
            id: 1,
            name: '2207-НД',
            base_groups: [0],
            members: [1],
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        closed: false,
        marked: true,
        room_not_created: true,
        online_platform_room_id: 2,
        discipline: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          name: 'Алгебра',
          active: true,
          department_id: 21,
          location_id: 12,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        conduction_way: {
          id: 5,
          name: 'Дистанционное',
          active: true,
          created_at: null,
          slug: 'remote',
          updated_at: null,
        },
        lesson_type: {
          id: 1,
          name: 'Лекция',
          active: true,
          created_at: null,
          colour: '#D6DFFF',
        },
        classroom: {
          id: 1,
          type_id: 1,
          department_id: 1,
          floor_id: 1,
          block_id: 1,
          location_id: 1,
          students_amount: 100,
          active: true,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        online_platform_room: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          lesson_id: 1,
          online_platform_id: 1,
          teacher_link: 'https://zoom.com/teacher',
          student_link: 'https://zoom.com/student',
          data: {},
          created_at: '2022-08-13T08:08:12.000000Z',
          updated_at: '2022-08-13T08:08:12.000000Z',
        },
        lesson_numbers: [1, 2],
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-14T08:08:12.000000Z',
      },
      {
        id: 3,
        date: '2022-09-05',
        time_from: '09:00',
        time_to: '10:35',
        discipline_id: 1,
        conducting_way_id: 5,
        lesson_type_id: 1,
        classroom_id: 1,
        teachers: [
          {
            id: 1,
            external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
            last_name: 'Иванов',
            first_name: 'Иван',
            middle_name: 'Петрович',
            name: 'И.П. Иванов',
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        study_groups: [
          {
            id: 1,
            name: '2207-НД',
            base_groups: [0],
            members: [1],
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        closed: true,
        marked: false,
        room_not_created: false,
        online_platform_room_id: 2,
        discipline: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          name: 'Алгебра',
          active: true,
          department_id: 21,
          location_id: 12,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        conduction_way: {
          id: 5,
          name: 'Дистанционное',
          active: true,
          created_at: null,
          slug: 'remote',
          updated_at: null,
        },
        lesson_type: {
          id: 1,
          name: 'Лекция',
          active: true,
          created_at: null,
          colour: '#D6DFFF',
        },
        classroom: {
          id: 1,
          type_id: 1,
          department_id: 1,
          floor_id: 1,
          block_id: 1,
          location_id: 1,
          students_amount: 100,
          active: true,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        online_platform_room: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          lesson_id: 1,
          online_platform_id: 1,
          teacher_link: 'https://zoom.com/teacher',
          student_link: 'https://zoom.com/student',
          data: {},
          created_at: '2022-08-13T08:08:12.000000Z',
          updated_at: '2022-08-13T08:08:12.000000Z',
        },
        lesson_numbers: [1, 2],
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
      {
        id: 4,
        date: '2022-09-06',
        time_from: '09:00',
        time_to: '10:35',
        discipline_id: 1,
        conducting_way_id: 6,
        lesson_type_id: 1,
        classroom_id: 1,
        teachers: [
          {
            id: 1,
            external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
            last_name: 'Иванов',
            first_name: 'Иван',
            middle_name: 'Петрович',
            name: 'И.П. Иванов',
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        study_groups: [
          {
            id: 1,
            name: '2207-НД',
            base_groups: [0],
            members: [1],
            created_at: '2022-08-13T08:08:12.000000Z',
            updated_at: '2022-08-13T08:08:12.000000Z',
          },
        ],
        closed: true,
        marked: false,
        room_not_created: false,
        online_platform_room_id: 2,
        discipline: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          name: 'Алгебра',
          active: true,
          department_id: 21,
          location_id: 12,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        conduction_way: {
          id: 6,
          name: 'Смешанное',
          active: true,
          created_at: null,
          slug: 'mixed',
          updated_at: null,
        },
        lesson_type: {
          id: 1,
          name: 'Лекция',
          active: true,
          created_at: null,
          colour: '#D6DFFF',
        },
        classroom: {
          id: 1,
          type_id: 1,
          department_id: 1,
          floor_id: 1,
          block_id: 1,
          location_id: 1,
          students_amount: 100,
          active: true,
          created_at: '2022-08-13T08:08:12.000000Z',
        },
        online_platform_room: {
          id: 1,
          external_id: 'c3ab183f975fb4e2e9f1730fe3301ace',
          lesson_id: 1,
          online_platform_id: 1,
          teacher_link: 'https://zoom.com/teacher',
          student_link: 'https://zoom.com/student',
          data: {},
          created_at: '2022-08-13T08:08:12.000000Z',
          updated_at: '2022-08-13T08:08:12.000000Z',
        },
        lesson_numbers: [1, 3, 6],
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
    ],
    current_page: 1,
    last_page: 2,
    per_page: 20,
    total: 40,
  },
}
export const mockSettingRecipientsAppeal = {
  result: {
    data: [
      {
        id: 1,
        faculty: { name: 'Физический', id: 1 },
        theme: 'Обращение к декану факультета',
        course: 2,
        recipients: 'Иванов Иван Иванович',
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
      {
        id: 2,
        faculty: { name: 'Математический', id: 2 },
        theme: 'Обращение к декану факультета',
        course: 3,
        recipients: 'Иванов Иван Иванович',
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
    ],
    current_page: 1,
    last_page: 4,
    per_page: 20,
    total: 2,
  },
}

export const mockSettingRecipientsReferences = {
  result: {
    data: [
      {
        id: 1,
        faculty: { name: 'Физический', id: 1 },
        theme: 'Учебная карточка',
        course: 2,
        recipients: 'Иванов Иван Иванович',
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
      {
        id: 2,
        faculty: { name: 'Математический', id: 2 },
        theme: 'Справка по месту работы родителей',
        course: 3,
        recipients: 'Иванов Иван Иванович',
        created_at: '2022-08-13T08:08:12.000000Z',
        updated_at: '2022-08-13T08:08:12.000000Z',
      },
    ],
    current_page: 1,
    last_page: 4,
    per_page: 20,
    total: 20,
  },
}

export const mockScheduleStudyGroup = {
  result: {
    data: [
      {
        id: 1,
        type: 'union',
        name: 'Название группы 2',
        base_groups: [
          {
            id: 7,
            name: '143-ПА',
          },
          {
            id: 8,
            name: '144-ПА',
          },
          {
            id: 9,
            name: '145-ПА',
          },
          {
            id: 10,
            name: '146-ПА',
          },
        ],
        members: [554, 65, 54, 767],
      },
      {
        id: 2,
        type: 'separation',
        name: 'Название группы 3',
        base_groups: [
          {
            id: 7,
            name: '143-ПА',
          },
        ],
        members: [554, 65, 54, 767],
      },
      {
        id: 3,
        type: 'separation',
        name: 'Название группы 4',
        base_groups: [
          {
            id: 7,
            name: '143-ПА',
          },
          {
            id: 8,
            name: '144-ПА',
          },
        ],
        members: [554, 65, 54, 767],
      },
    ],
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 1,
  },
}
export const mockScheduleAcademicPlansData = {
  result: {
    data: [
      {
        id: 1,
        discipline: {
          id: 6,
          name: 'Алгебра',
          active: true,
          created_at: '2022-05-20T00:00:00.000Z',
        },
        teachers: [
          {
            id: 3,
            batch_id: 34,
            data: {
              id: 32,
              external_id: 45,
              last_name: 'Иванов',
              first_name: 'Иван',
              middle_name: 'Иванов',
              created_at: '2022-05-20T00:00:00.000Z',
              updated_at: '2022-05-20T00:00:00.000Z',
            },
          },
          {
            id: 4,
            batch_id: 34,
            data: {
              id: 42,
              external_id: 45,
              last_name: 'Иванов',
              first_name: 'Петров',
              middle_name: 'Иванов',
              created_at: '2022-05-20T00:00:00.000Z',
              updated_at: '2022-05-20T00:00:00.000Z',
            },
          },
        ],
        groups: [
          {
            id: 1,
            batch_id: 34,
            data: {
              id: 9,
              name: '145-ПА',
              base_groups: [4333, 566],
              first_name: 'Иван',
              middle_name: 'Иванов',
              created_at: '2022-05-20T00:00:00.000Z',
              updated_at: '2022-05-20T00:00:00.000Z',
            },
          },
        ],
        batches: [1, 334, 34, 566],
      },
      {
        id: 2,
        discipline: {
          id: 59,
          name: 'Физика',
          active: true,
          created_at: '2022-05-20T00:00:00.000Z',
        },
        teachers: [],
        groups: [
          {
            id: 1,
            batch_id: 34,
            data: {
              id: 9,
              name: '145-ПА',
              base_groups: [4333, 566],
              first_name: 'Иван',
              middle_name: 'Иванов',
              created_at: '2022-05-20T00:00:00.000Z',
              updated_at: '2022-05-20T00:00:00.000Z',
            },
          },
          {
            id: 2,
            batch_id: 34,
            data: {
              id: 10,
              name: '146-ПА',
              base_groups: [4333, 566],
              first_name: 'Иван',
              middle_name: 'Иванов',
              created_at: '2022-05-20T00:00:00.000Z',
              updated_at: '2022-05-20T00:00:00.000Z',
            },
          },
        ],
        batches: [1, 334, 34, 566],
      },
    ],
    current_page: 1,
    last_page: 5,
    per_page: 10,
    total: 100,
  },
}
export const mockScheduleAcademicPlansDataById = {
  result: {
    data: [
      {
        id: 1,
        discipline: {
          id: 6,
          name: 'Алгебра',
          active: true,
          created_at: '2022-05-20T00:00:00.000Z',
        },
        batches: [
          {
            id: 34,
            teachers: [
              {
                id: 3,
                batch_id: 34,
                data: {
                  id: 42,
                  external_id: 45,
                  name: 'Петров Иван',
                  last_name: 'Иванов',
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
            groups: [
              {
                id: 1,
                batch_id: 34,
                data: {
                  id: 9,
                  name: '145-ПА',
                  base_groups: [4333, 566],
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
          },
          {
            id: 100,
            teachers: [
              {
                id: 3,
                batch_id: 100,
                data: {
                  id: 42,
                  external_id: 45,
                  name: 'Петров Иван',
                  last_name: 'Петров',
                  first_name: 'Петр',
                  middle_name: 'Петрович',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
            groups: [
              {
                id: 1,
                batch_id: 100,
                data: {
                  id: 9,
                  name: '145-ПА',
                  base_groups: [4333, 566],
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
          },
        ],
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
      {
        id: 2,
        discipline: {
          id: 59,
          name: 'Физика',
          active: true,
          created_at: '2022-05-20T00:00:00.000Z',
        },
        batches: [
          {
            id: 34,
            teachers: [],
            groups: [
              {
                id: 1,
                batch_id: 34,
                data: {
                  id: 9,
                  name: '145-ПА',
                  base_groups: [4333, 566],
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
          },
          {
            id: 100,
            teachers: [
              {
                id: 3,
                batch_id: 100,
                data: {
                  id: '32',
                  external_id: 45,
                  name: 'Иванов Иван',
                  last_name: 'Петров',
                  first_name: 'Петр',
                  middle_name: 'Петрович',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
            groups: [
              {
                id: 1,
                batch_id: 34,
                data: {
                  id: 9,
                  name: '145-ПА',
                  base_groups: [4333, 566],
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
              {
                id: 2,
                batch_id: 34,
                data: {
                  id: 10,
                  name: '146-ПА',
                  base_groups: [4333, 566],
                  first_name: 'Иван',
                  middle_name: 'Иванов',
                  created_at: '2022-05-20T00:00:00.000Z',
                  updated_at: '2022-05-20T00:00:00.000Z',
                },
              },
            ],
          },
        ],
        created_at: '2022-05-20T00:00:00.000Z',
        updated_at: '2022-05-20T00:00:00.000Z',
      },
    ],
  },
}

export const mockAllStipend = {
  result: {
    data: [
      {
        id: 1,
        date_from: '01.06.2022',
        date_to: '06.30.2022',
        group: {
          id: 123,
          name: '313',
        },
        viewed: false,
        sum: 10000.35,
        payment_date: '25.06.2022',
        file_link: '',
      },
      {
        id: 2,
        date_from: '1.07.2022',
        date_to: '30.07.2022',
        group: {
          id: 123,
          name: '313',
        },
        viewed: true,
        sum: 10000.35,
        payment_date: '25.07.2022',
        file_link: '',
      },
    ],
    current_page: 1,
    last_page: 5,
    per_page: 10,
    total: 100,
  },
}

export const getMockSettingData = (id) => {
  return {
    result: {
      data: [...mockSettingData.filter((el) => el.category_id === id)],
    },
  }
}
export const getMockScheduleStudyGroupByID = (id) => {
  return {
    result: {
      data: [...mockScheduleStudyGroup.result.data.filter((el) => el.id === Number(id))],
    },
  }
}
export const getMockScheduleAcademicPlansByID = (id) => {
  return {
    result: {
      data: [...mockScheduleAcademicPlansDataById.result.data.filter((el) => el.id === Number(id))],
    },
  }
}
export const getMockScheduleByID = (id) => {
  return {
    result: {
      data: [...mockScheduleData.result.data.filter((el) => el.id === Number(id))],
    },
  }
}
export const getMockHomeworkByID = (id) => {
  return {
    result: {
      data: [...mockAllHomeWorkData.result.data.filter((el) => el.id === Number(id))],
    },
  }
}
export const getMockHomeTaskByID = (id) => {
  return {
    result: {
      data: [...mockAllHomeTaskData.result.data.filter((el) => el.id === Number(id))],
    },
  }
}

export const getMockSettingRecipientsAppealByID = (id) => {
  return {
    result: {
      data: mockSettingRecipientsAppeal.result.data.find((el) => el.id === Number(id)),
    },
  }
}

export const getMockSettingRecipientsReferencesID = (id) => {
  return {
    result: {
      data: mockSettingRecipientsReferences.result.data.find((el) => el.id === Number(id)),
    },
  }
}

import { REMOVE_LOADING, SET_LOADING } from './loadingTypes'

const initialState = {
  show: true,
}

const loadingReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...prevState, show: true }
    case REMOVE_LOADING:
      return { ...prevState, show: false }
    default:
      return prevState
  }
}

export default loadingReducer

import { GET_APPEALS_STATISTICS, GET_CERTORDERS_STATISTICS } from './dashboardTypes'

const initialState = {
  appealsStatistics: {},
  certOrdersStatistics: {},
}

const dashboardReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_APPEALS_STATISTICS:
      return { ...prevState, appealsStatistics: action.data }
    case GET_CERTORDERS_STATISTICS:
      return { ...prevState, certOrdersStatistics: action.data }
    default:
      return prevState
  }
}

export default dashboardReducer

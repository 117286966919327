import { SET_LOADING, REMOVE_LOADING } from './loadingTypes'
let removeTimeout = null

export const setLoading = () => {
  clearTimeout(removeTimeout)
  return {
    type: SET_LOADING,
  }
}

export const removeLoading = () => {
  return async (dispatch) => {
    clearTimeout(removeTimeout)
    return new Promise((resolve, reject) => {
      removeTimeout = setTimeout(() => {
        resolve(
          dispatch({
            type: REMOVE_LOADING,
          }),
        )
      }, 300)
    })
  }
}

export const GET_HOME_TASK = 'GET_HOME_TASK'
export const GET_HOME_TASK_BY_ID = 'GET_HOME_TASK_BY_ID'

export const CREATE_HOME_TASK = 'CREATE_HOME_TASK'
export const UPDATE_HOME_TASK = 'UPDATE_HOME_TASK'
export const RESET_HOME_TASK = 'RESET_HOME_TASK'

export const RESET_CREATE_HOME_TASK = 'RESET_CREATE_HOME_TASK'

export const GET_DISCIPLINES = 'GET_DISCIPLINES'
export const GET_STUDY_GROUPS = 'GET_STUDY_GROUPS'
export const RESET_STUDY_GROUPS = 'RESET_STUDY_GROUPS'

const initialState = {
  sidebarShow: true,
}
/**
 * Хранилище для флага отображения сайдбара
 * @param state
 * @param type
 * @param rest
 * @returns {(*&{sidebarShow: boolean})|{sidebarShow: boolean}}
 */
const sideBarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}
export default sideBarReducer

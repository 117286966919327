import {
  CREATE_ONLINE_ROOM,
  DELETE_ONLINE_ROOM,
  GET_CONNECTION_LINK_TO_BBB_ROOM,
  RESET_CREATED_ROOM,
  RESET_GET_CONNECTION_LINK_TO_BBB_ROOM,
  SET_IS_LOADING_FALSE,
  SET_IS_LOADING_TRUE,
  UPDATE_ONLINE_ROOM,
} from './onlineRoomTypes'

const initialState = {
  isLoading: false,
  createdRoom: {},
  connectionData: {},
  bbbRoomGetSuccess: false,
}

const onlineRoomReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case CREATE_ONLINE_ROOM:
      return {
        ...prevState,
        createdRoom: action.data.data.result,
      }
    case UPDATE_ONLINE_ROOM:
      return {
        ...prevState,
        // createdRoom: action.data.data.result,
      }
    case DELETE_ONLINE_ROOM:
      return {
        ...prevState,
        createdRoom: {},
      }
    case SET_IS_LOADING_TRUE:
      return {
        ...prevState,
        isLoading: true,
      }
    case SET_IS_LOADING_FALSE:
      return {
        ...prevState,
        isLoading: false,
      }
    case RESET_CREATED_ROOM:
      return {
        ...prevState,
        createdRoom: {},
      }
    case GET_CONNECTION_LINK_TO_BBB_ROOM:
      return {
        ...prevState,
        connectionData: action.data.data.result,
        bbbRoomGetSuccess: true,
      }
    case RESET_GET_CONNECTION_LINK_TO_BBB_ROOM:
      return {
        ...prevState,
        bbbRoomGetSuccess: false,
      }
    default:
      return prevState
  }
}

export default onlineRoomReducer

import { GET_HANDBOOK_DATA, GET_PROVIDER_SYSTEMS_DATA } from './handbookTypes'

const initialState = {
  ready: false,
  result: {
    course: [
      {
        id: 1,
        name: '1 курс',
      },
      {
        id: 2,
        name: '2 курс',
      },
      {
        id: 3,
        name: '3 курс',
      },
      {
        id: 4,
        name: '4 курс',
      },
      {
        id: 5,
        name: '5 курс',
      },
      {
        id: 6,
        name: '6 курс',
      },
    ],
    userStatus: [
      {
        id: 'new',
        name: 'Новый',
      },
      {
        id: 'invited',
        name: 'Приглашён',
      },
      {
        id: 'registration_finished',
        name: 'Завершил регистрацию',
      },
    ],
  },
  aliases: {},
}

const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

/**
 * Хранилище для справочников
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const handbookReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_HANDBOOK_DATA:
      let result = {}
      let aliases = {}
      Object.entries(action.data.data.result).forEach(([code, values]) => {
        const camelCaseCode = snakeToCamel(code)
        result[camelCaseCode] = values
        if (camelCaseCode !== code) {
          aliases[code] = camelCaseCode
        }
      })

      return {
        ...prevState,
        ready: true,
        result: {
          ...prevState.result,
          ...result,
        },
        aliases: {
          ...prevState.aliases,
          ...aliases,
        },
      }
    case GET_PROVIDER_SYSTEMS_DATA:
      return {
        ...prevState,
        result: { ...prevState.result, providerSystems: [...action.data.data.result.data] },
      }
    default:
      return prevState
  }
}

export default handbookReducer

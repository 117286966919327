import { useKeycloak } from '@react-keycloak/web'
import UserAccess from '../../classes/UserAccess'

import * as Constants from '../../constants'

/**
 * Хук для проверки авторизации пользователя
 * @returns {{keycloak: Keycloak, access: UserAccess, initialized: boolean, allRoles: {d_employee: string, schedule_operator: string, teacher: string, student: string, a_employee: string, admin: string, employee: string, headman: string}}}
 */
const useAuth = () => {
  const keycloak = useKeycloak()

  // К этому моменту единственный экземпляр класса уже инициализирован в AppAuthProvider
  const access = new UserAccess([])

  return {
    keycloak: keycloak.keycloak,
    initialized: keycloak.initialized,
    access,
    allRoles: Constants.allRoles,
  }
}

export default useAuth

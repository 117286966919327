import {
  GET_HOME_WORK,
  GET_HOME_WORK_BY_ID,
  UPDATE_HOME_WORK_BY_ID,
  RESET_UPDATE_HOME_WORK_BY_ID,
} from './homeWorkTypes'
import { setError } from '../errorReducer'
import api from '../../services/api'

export const getAllHomeWorkUrl = '/homeworks'

export const getAllHomeWork = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(getAllHomeWorkUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_HOME_WORK,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getHomeWorkById = (params) => {
  return async (dispatch) => {
    api
      .get(getAllHomeWorkUrl + '/' + params.id)
      .then((res) =>
        dispatch({
          type: GET_HOME_WORK_BY_ID,
          id: params.id,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateHomeWorkById = ({ id, body }) => {
  return async (dispatch) => {
    return api
      .post(getAllHomeWorkUrl + '/' + id + '?_method=PUT', body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_HOME_WORK_BY_ID,
          id: body.id,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_UPDATE_HOME_WORK_BY_ID,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { addToastAction } from '../../store/toastReducer'
import { CToast, CToastHeader, CToastBody, CToastClose } from '@coreui/react'

/**
 * Хук для добавления всплывающих сообщений
 * @returns {(function(*, *=): void)|*}
 */
const useToast = () => {
  const dispatch = useDispatch()

  /**
   * Добавление сообщения
   * @param {string} message
   * @param {object} settings
   * @param {boolean} object.autoHide
   * @param {number} object.delay
   * @param {boolean} object.canClose
   * @param {boolean} object.disabled
   * @param {string} object.type
   */
  return (message, settings = {}) => {
    if (!message) {
      return
    }
    const autoHide = 'autoHide' in settings ? !!settings.autoHide : true
    const delay = settings.delay || 5000
    const canClose = 'canClose' in settings ? !!settings.canClose : true
    const disabled = settings.disabled || false
    let color
    let classes = 'align-items-center'
    switch (settings.type) {
      case 'error':
        color = 'danger'
        break
      case 'success':
        color = settings.type
        break
      default:
        color = null
    }

    if (color) {
      classes += ' text-white'
    }

    const toast = (
      <CToast autohide={autoHide} delay={delay} color={color} className={classes}>
        <div className="d-flex">
          <CToastBody>{message}</CToastBody>
          {canClose && <CToastClose className="me-2 m-auto" white={!!color} disabled={disabled} />}
        </div>
      </CToast>
    )
    dispatch(addToastAction(toast))
  }
}

export default useToast

import * as Constants from '../constants'

/**
 * Класс для проверки прав пользователя
 */
export default class UserAccess {
  constructor(roles, user = {}) {
    if (UserAccess._instance) {
      return UserAccess._instance
    }
    this.roles = Object.values(Constants.allRoles).filter((x) => roles.includes(x))
    this.customRoles = {}
    this.user = user

    if (
      this.roles.includes(Constants.STUDENT_ROLE) &&
      this.user.profiles.some((el) => el.is_headman)
    ) {
      this.customRoles[Constants.STUDENT_ROLE] = [Constants.HEADMAN_ROLE]
    }
    if (this.user.headman_manager) {
      for (const role of this.roles) {
        if (this.customRoles[role]) {
          this.customRoles[role]?.push(Constants.HEADMAN_MANAGER_ROLE)
        } else {
          this.customRoles[role] = [Constants.HEADMAN_MANAGER_ROLE]
        }
      }
    }
    if (this.user.absence_manager) {
      for (const role of this.roles) {
        if (this.customRoles[role]) {
          this.customRoles[role]?.push(Constants.ABSENCE_MANAGER_ROLE)
        } else {
          this.customRoles[role] = [Constants.ABSENCE_MANAGER_ROLE]
        }
      }
    }
    if (!this.roles || !this.roles.length || !this.user?.profiles?.length) {
      this.roles = [Constants.UNDEFINED_ROLE]
    }
    localStorage.setItem('currentRole', this.getCurrentRole())
    UserAccess._instance = this
  }

  getCurrentRole() {
    let currentRole = localStorage.getItem('currentRole')
    if (this.roles.length && !this.roles.includes(currentRole)) {
      currentRole = this.roles[0]
    }

    return currentRole
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль с указанным кодом
   * @param {string} roleCode
   */
  has(roleCode) {
    return [...this.roles, ...Object.values(this.customRoles)].includes(roleCode)
  }

  /**
   * Проверяет, есть ли у текущего пользователя хотя бы одна роль из указанных с учетом текущей роли
   * @param roleCodes
   * @returns {boolean}
   */
  hasOneOf(roleCodes) {
    const sameRoles = roleCodes.filter((x) => this.has(x))
    if (sameRoles.length > 0) {
      return (
        sameRoles.includes(this.getCurrentRole()) ||
        (this.getCurrentCustomRoles() && sameRoles.includes(this.getCurrentCustomRoles()))
      )
    } else {
      return this.roles.some(
        (role) => !!this.getCurrentCustomRoles(role)?.some((el) => roleCodes.includes(el)),
      )
    }
  }

  /**
   * Получает текущие кастомные роли, если они есть
   * @returns {*|*[]}
   */
  getCurrentCustomRoles() {
    return this.customRoles[this.getCurrentRole()] || []
  }

  /**
   * Проверяет, есть ли у текущего пользователя хотя бы одна роль из указанных без учета текущей роли.
   * Т.е. результат метода не зависит от текущей выбранной роли.
   * @param roleCodes
   * @returns {boolean}
   */
  can(roleCodes) {
    return roleCodes.filter((x) => this.has(x))?.length > 0
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Админ"
   * @returns {boolean|*}
   */
  isAdmin() {
    return this.getCurrentRole() === Constants.ADMIN_ROLE && this.has(Constants.ADMIN_ROLE)
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Студент"
   * @returns {boolean|*}
   */
  isStudent() {
    return this.getCurrentRole() === Constants.STUDENT_ROLE && this.has(Constants.STUDENT_ROLE)
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Староста"
   * @returns {boolean|*}
   */
  isHeadman() {
    return (
      this.isStudent() &&
      this.getCurrentCustomRoles(Constants.STUDENT_ROLE).includes(Constants.HEADMAN_ROLE)
    )
  }

  /**
   * Проверяет, является ли текущий пользователь управляющим старостами
   * @returns {boolean|*}
   */
  isHeadmanManager() {
    return this.roles.some((role) =>
      this.getCurrentCustomRoles(role).includes(Constants.HEADMAN_MANAGER_ROLE),
    )
  }

  /**
   * Проверяет, имеет ли текущий пользователь доступ к управлению причинами неявки
   * @returns {boolean|*}
   */
  isAbsenceManager() {
    return this.roles.some((role) =>
      this.getCurrentCustomRoles(role).includes(Constants.ABSENCE_MANAGER_ROLE),
    )
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Преподаватель"
   * @returns {boolean|*}
   */
  isTeacher() {
    return this.getCurrentRole() === Constants.TEACHER_ROLE && this.has(Constants.TEACHER_ROLE)
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Сотрудник"
   * @returns {boolean|*}
   */
  isEmployee() {
    return this.getCurrentRole() === Constants.EMPLOYEE_ROLE && this.has(Constants.EMPLOYEE_ROLE)
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Сотрудник учебной части"
   * @returns {boolean|*}
   */
  isAcademicEmployee() {
    return (
      this.getCurrentRole() === Constants.A_EMPLOYEE_ROLE && this.has(Constants.A_EMPLOYEE_ROLE)
    )
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Оператор расписания"
   * @returns {boolean|*}
   */
  isScheduleOperator() {
    return (
      this.getCurrentRole() === Constants.SCHEDULE_OPERATOR_ROLE &&
      this.has(Constants.SCHEDULE_OPERATOR_ROLE)
    )
  }

  /**
   * Проверяет, есть ли у текущего пользователя роль "Сотрудник кафедры"
   * @returns {boolean|*}
   */
  isDepartmentEmployee() {
    return (
      this.getCurrentRole() === Constants.D_EMPLOYEE_ROLE && this.has(Constants.D_EMPLOYEE_ROLE)
    )
  }

  /**
   * Не удалось определить текущую роль пользователя
   * @returns {boolean|*}
   */
  isUndefined() {
    return this.getCurrentRole() === Constants.UNDEFINED_ROLE && this.has(Constants.UNDEFINED_ROLE)
  }
}

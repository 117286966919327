import {
  CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA,
  CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA,
  DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA,
  GET_ALL_RECIPIENTS_APPEAL_DATA,
  GET_ALL_RECIPIENTS_REFERENCES_DATA,
  GET_RECIPIENTS_APPEAL_DATA_BY_ID,
  GET_RECIPIENTS_REFERENCES_DATA_BY_ID,
  GET_SETTING_BY_ID,
  GET_SETTING_CATEGORY_BY_ID,
  GET_SETTING_CATEGORY_BY_CODE,
  RESET_SETTING_WAS_CREATE_OR_UPDATE,
  RESET_SETTING_WAS_DELETED,
  GET_LESSON_EDITING_PROHIBITIONS,
  RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED,
  DELETE_LESSON_EDITING_PROHIBITIONS,
  CREATE_LESSON_EDITING_PROHIBITIONS,
  UPDATE_LESSON_EDITING_PROHIBITIONS,
  RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE,
  GET_LESSON_EDITING_PROHIBITIONS_BY_ID,
  GET_HEADMANS_MANAGEMENT_ITEMS,
  GET_HEADMANS_MANAGEMENT_ITEM,
  CLEAR_HEADMANS_MANAGEMENT_ITEM,
} from './settingTypes'

const initialState = {
  parentPages: {},
  settingCategory: {},
  settings: {},
  recipients: {
    appealByID: {},
    referencesByID: {},
  },
  lessonEditingProhibitions: {
    lessonEditingProhibitionsById: {},
  },
  headmansManagementItems: [],
  headmansManagementItem: null,
  settingWasCreateOrUpdate: false,
  settingWasDeleted: false,
  lessonEditingProhibitionsWasCreateOrUpdate: false,
  lessonEditingProhibitionsWasDeleted: false,
}

/**
 * Хранилище настроек приложения
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const settingReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_SETTING_CATEGORY_BY_ID:
      return {
        ...prevState,
        settingCategory: {
          ...prevState.settingCategory,
          [action.parentCategoryId]: { ...action.data.data },
        },
      }
    case GET_SETTING_CATEGORY_BY_CODE:
      return {
        ...prevState,
        settingCategory: {
          ...prevState.settingCategory,
          [action.parentCategoryCode]: { ...action.data.data },
        },
      }
    case GET_SETTING_BY_ID:
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          [action.settingId]: { ...action.data.data },
        },
      }
    case GET_ALL_RECIPIENTS_REFERENCES_DATA:
      return {
        ...prevState,
        recipients: {
          ...prevState.recipients,
          references: action.data.data.result,
        },
      }
    case GET_ALL_RECIPIENTS_APPEAL_DATA:
      return {
        ...prevState,
        recipients: {
          ...prevState.recipients,
          appeal: action.data.data.result,
        },
      }
    case GET_RECIPIENTS_REFERENCES_DATA_BY_ID:
      return {
        ...prevState,
        recipients: {
          ...prevState.recipients,
          referencesByID: {
            ...prevState.recipients.referencesByID,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA:
      return {
        ...prevState,
        settingWasCreateOrUpdate: true,
        recipients: {
          ...prevState.recipients,
          referencesByID: {
            ...prevState.recipients.referencesByID,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA:
      return {
        ...prevState,
        settingWasCreateOrUpdate: true,
        recipients: {
          ...prevState.recipients,
          appealByID: {
            ...prevState.recipients.appealByID,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case GET_RECIPIENTS_APPEAL_DATA_BY_ID:
      return {
        ...prevState,
        recipients: {
          ...prevState.recipients,
          appealByID: {
            ...prevState.recipients.appealByID,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA:
      return {
        ...prevState,
        settingWasDeleted: true,
      }
    case RESET_SETTING_WAS_CREATE_OR_UPDATE:
      return {
        ...prevState,
        settingWasCreateOrUpdate: false,
      }
    case RESET_SETTING_WAS_DELETED:
      return {
        ...prevState,
        settingWasDeleted: false,
      }
    case GET_LESSON_EDITING_PROHIBITIONS:
      return {
        ...prevState,
        lessonEditingProhibitions: {
          ...prevState.lessonEditingProhibitions,
          ...action.data.data.result,
        },
      }
    case GET_LESSON_EDITING_PROHIBITIONS_BY_ID:
      return {
        ...prevState,
        lessonEditingProhibitions: {
          ...prevState.lessonEditingProhibitions,
          lessonEditingProhibitionsById: {
            ...prevState.lessonEditingProhibitions.lessonEditingProhibitionsById,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case CREATE_LESSON_EDITING_PROHIBITIONS:
      return {
        ...prevState,
        lessonEditingProhibitionsWasCreateOrUpdate: true,
      }
    case UPDATE_LESSON_EDITING_PROHIBITIONS:
      return {
        ...prevState,
        lessonEditingProhibitionsWasCreateOrUpdate: true,
        lessonEditingProhibitions: {
          ...prevState.lessonEditingProhibitions,
          lessonEditingProhibitionsById: {
            ...prevState.lessonEditingProhibitions.lessonEditingProhibitionsById,
            [action.data.data.result.id]: action.data.data.result,
          },
        },
      }
    case RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE:
      return {
        ...prevState,
        lessonEditingProhibitionsWasCreateOrUpdate: false,
      }
    case DELETE_LESSON_EDITING_PROHIBITIONS:
      return {
        ...prevState,
        lessonEditingProhibitionsWasDeleted: true,
      }
    case RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED:
      return {
        ...prevState,
        lessonEditingProhibitionsWasDeleted: false,
      }
    case GET_HEADMANS_MANAGEMENT_ITEMS:
      return {
        ...prevState,
        headmansManagementItems: action.data,
      }
    case GET_HEADMANS_MANAGEMENT_ITEM:
      return {
        ...prevState,
        headmansManagementItem: action.data,
      }
    case CLEAR_HEADMANS_MANAGEMENT_ITEM:
      return {
        ...prevState,
        headmansManagementItem: null,
      }
    default:
      return prevState
  }
}

export default settingReducer

import {
  GET_ALL_APPEALS,
  GET_APPEALS_BY_ID,
  GET_APPEALS_FIELDS,
  GET_APPEALS_THEMES,
  RESET_APPEALS_STATE,
  SET_APPEAL_FETCH_IN_PROGRESS,
} from './appealTypes'

const initialState = {
  allAppeal: {},
  fields: {},
  themes: [],
  fetchInProgress: false,
}

const appealReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_APPEALS:
      return { ...prevState, allAppeal: action.data, fetchInProgress: false }
    case GET_APPEALS_BY_ID:
      return {
        ...prevState,
        [action.data.appeal.id]: { ...action.data.appeal },
        fields: action.data.fields,
      }
    case GET_APPEALS_FIELDS:
      return {
        ...prevState,
        fields: action.data,
      }
    case GET_APPEALS_THEMES:
      return { ...prevState, themes: action.data }
    case RESET_APPEALS_STATE:
      return initialState
    case SET_APPEAL_FETCH_IN_PROGRESS:
      return { ...prevState, fetchInProgress: action.data }
    default:
      return prevState
  }
}
export default appealReducer

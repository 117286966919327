import { GET_PAYSLIPS, SET_VIEWED, SET_VIEWED_ALL, GET_FILTER_POSITIONS } from './paySlipsTypes'

const initialState = {
  paySlips: {},
  filter: {
    positions: [],
  },
}

const paySlipsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_PAYSLIPS:
      return { ...prevState, paySlips: action.data }
    case SET_VIEWED:
      return {
        ...prevState,
        paySlips: {
          ...prevState.paySlips,
          data: prevState.paySlips.data.map((el) => {
            if (action.data.includes(el.id)) {
              return { ...el, viewed: true }
            }
            return el
          }),
        },
      }
    case SET_VIEWED_ALL:
      return {
        ...prevState,
        paySlips: {
          ...prevState.paySlips,
          data: prevState.paySlips.data.map((el) => ({ ...el, viewed: true })),
        },
      }
    case GET_FILTER_POSITIONS:
      return {
        ...prevState,
        filter: {
          positions: action.data.map((el) => ({
            id: el,
            name: el,
          })),
        },
      }
    default:
      return prevState
  }
}

export default paySlipsReducer

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ym, { YMInitializer } from 'react-yandex-metrika'

const YM_COUNTER_ID = Number(process.env.REACT_APP_YANDEX_METRIKA_ID)

export const MetrikaInitializer = () => {
  return <YMInitializer accounts={[YM_COUNTER_ID]} options={{ defer: true }} />
}

export const MetrikaRouterObserver = () => {
  const location = useLocation()
  const [currentTitle, setCurrentTitle] = useState(document.title)

  useEffect(() => {
    const checkTitle = () => {
      if (document.title !== currentTitle) {
        setCurrentTitle(document.title)
        ym('hit')
      }
    }

    const intervalId = setInterval(checkTitle, 100)

    return () => clearInterval(intervalId)
  }, [location, currentTitle])

  return null
}

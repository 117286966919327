import api from '../../services/api'
import { setError } from '../errorReducer'
import { READ_NOTIFICATION, GET_COUNTERS } from './notificationTypes'

export const readNotificationUrl = '/notifications/read'
export const getCountersUrl = '/notifications/unreadCounters'

export const readNotification = (params) => {
  return async (dispatch) => {
    return api
      .post(readNotificationUrl, { ...params })
      .then((res) =>
        dispatch({
          type: READ_NOTIFICATION,
          data: params,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
  }
}

export const getCounters = (config) => {
  return async (dispatch) => {
    return api
      .get(getCountersUrl, { ...config })
      .then((response) =>
        dispatch({
          type: GET_COUNTERS,
          data: response.data.result,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
  }
}

export const getPermissions = (userId, config = {}) => {
  return async (dispatch) =>
    api
      .get(`/notification_permissions/${userId}`, {
        ...config,
      })
      .then((response) => response)
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
}

import {
  GET_COWORKER_ACCESS_USERS_COUNT,
  GET_PROGRESS_USERS,
  GET_USER_EMPLOYEE_FOR_TREE,
  GET_USERS_FROM_SEARCH,
} from './usersTypes'

const initialState = {
  users: [],
  usersInTree: [],
  userEmployeeInTree: [],
  coworkerAccessTotalCount: 0,
  inviteEmail: null,
}

/**
 * Хранилище для пользователей
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const usersProgressReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_PROGRESS_USERS:
      return {
        ...prevState,
        users: action.data,
      }
    case GET_USERS_FROM_SEARCH:
      return {
        ...prevState,
        usersInTree: action.data,
      }
    case GET_USER_EMPLOYEE_FOR_TREE:
      return {
        ...prevState,
        userEmployeeInTree: action.data,
      }
    case GET_COWORKER_ACCESS_USERS_COUNT:
      return {
        ...prevState,
        coworkerAccessTotalCount: action.data,
      }
    default:
      return prevState
  }
}

export default usersProgressReducer

export const GET_ALL_ANNOUNCEMENT = 'GET_ALL_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT_BY_ID = 'GET_ANNOUNCEMENT_BY_ID'
export const EDIT_ANNOUNCEMENT_BY_ID = 'EDIT_ANNOUNCEMENT_BY_ID'

export const SET_VIEWED_ANNOUNCEMENT = 'SET_VIEWED_ANNOUNCEMENT'
export const SET_VIEWED_ALL_ANNOUNCEMENT = 'SET_VIEWED_ALL_ANNOUNCEMENT'

export const ANNOUNCEMENT_WAS_DELETE = 'ANNOUNCEMENT_WAS_DELETE'
export const RESET_ANNOUNCEMENT_WAS_DELETE = 'RESET_ANNOUNCEMENT_WAS_DELETE'

export const CLEAR_STATE = 'CLEAR_STATE'

const initialState = []

const SET = 'SET_CUSTOM_BREADCRUMBS'
/**
 * Хранилище для кастомных динамических хлебных крошек
 * @param state
 * @param type
 * @param rest
 * @returns {(*&{name: string})|{name: string}}
 */
const customBreadcrumbReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET:
      return [...rest.breadcrumbs]
    default:
      return state
  }
}
export default customBreadcrumbReducer
export const setBreadcrumbs = (breadcrumbs) => ({ type: SET, breadcrumbs })

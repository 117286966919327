import { mock } from '../../services/api-mock'
import {
  getMockHomeTaskByID,
  getMockHomeworkByID,
  getMockScheduleAcademicPlansByID,
  getMockScheduleByID,
  getMockScheduleStudyGroupByID,
  getMockSettingData,
  getMockSettingRecipientsAppealByID,
  getMockSettingRecipientsReferencesID,
  mockAdditionalSettingData,
  mockAllHomeTaskData,
  mockAllHomeWorkData,
  mockAllStipend,
  mockFooterSettingData,
  mockRecipientsSettingData,
  mockScheduleAcademicPlansData,
  mockScheduleData,
  mockScheduleStudyGroup,
  mockSettingRecipientsAppeal,
  mockSettingRecipientsReferences,
} from './mockData'
import { getAllHomeWorkUrl } from '../homework/homeWorkAction'
import {
  getSettingByIdUrl,
  getSettingCategoryByIdUrl,
  getSettingRecipientsAppealUrl,
  getSettingRecipientsReferencesUrl,
} from '../setting/settingAction'
import {
  getAllHomeScheduleUrl,
  getAllScheduleAcademicPlansUrl,
  getAllScheduleStudyGroupUrl,
} from '../schedule/scheduleAction'
import { getAllHomeTaskUrl } from '../homeTask/homeTaskAction'
import { stipendUrl } from '../stipend/stipendAction'

const setMockData = () => {
  mock.onGet(stipendUrl).reply((config) => {
    const { params } = config
    const response = mockAllStipend
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getAllHomeWorkUrl).reply((config) => {
    const { params } = config
    const response = mockAllHomeWorkData
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })
  mock.onGet(getSettingCategoryByIdUrl).reply((config) => {
    const { params } = config
    let response = {}
    switch (params.parentCategoryId) {
      case 14:
        response = mockAdditionalSettingData
        break
      default:
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getSettingByIdUrl).reply((config) => {
    const { params } = config
    let response = getMockSettingData(params.categoryId)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getAllHomeScheduleUrl).reply((config) => {
    const { params } = config
    let response = mockScheduleData
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getAllScheduleStudyGroupUrl).reply((config) => {
    const { params } = config
    let response = params.id ? getMockScheduleStudyGroupByID(params.id) : mockScheduleStudyGroup
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getAllScheduleAcademicPlansUrl).reply((config) => {
    const { params } = config
    let response = mockScheduleAcademicPlansData
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamScheduleAcademic = new RegExp(`${getAllScheduleAcademicPlansUrl}/*`)
  mock.onGet(urlWithIdParamScheduleAcademic).reply((config) => {
    const { params } = config
    let response = getMockScheduleAcademicPlansByID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamHomeScheduleUrl = new RegExp(`${getAllHomeScheduleUrl}/*`)
  mock.onGet(urlWithIdParamHomeScheduleUrl).reply((config) => {
    const { params } = config
    let response = getMockScheduleByID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamHomeworkUrl = new RegExp(`${getAllHomeWorkUrl}/*`)
  mock.onGet(urlWithIdParamHomeworkUrl).reply((config) => {
    const { params } = config
    let response = getMockHomeworkByID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onPost('/users/link').reply((config) => {
    /*const isReject = Math.round(Math.random() * 100) > 90

    if (isReject) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404])
        }, mock.delayResponse)
      })
    }*/

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, {}])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getAllHomeTaskUrl).reply((config) => {
    const { params } = config
    let response = mockAllHomeTaskData
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamHomeTaskUrl = new RegExp(`${getAllHomeTaskUrl}/*`)
  mock.onGet(urlWithIdParamHomeTaskUrl).reply((config) => {
    const { params } = config
    let response = getMockHomeTaskByID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  mock.onGet(getSettingRecipientsReferencesUrl).reply((config) => {
    const { params } = config
    let response = mockSettingRecipientsReferences
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })
  mock.onGet(getSettingRecipientsAppealUrl).reply((config) => {
    const { params } = config
    let response = mockSettingRecipientsAppeal
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamSettingRecipientsReferencesUrl = new RegExp(
    `${getSettingRecipientsReferencesUrl}/*`,
  )
  mock.onGet(urlWithIdParamSettingRecipientsReferencesUrl).reply((config) => {
    const { params } = config
    let response = getMockSettingRecipientsAppealByID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })

  const urlWithIdParamSettingRecipientsAppealUrl = new RegExp(`${getSettingRecipientsAppealUrl}/*`)
  mock.onGet(urlWithIdParamSettingRecipientsAppealUrl).reply((config) => {
    const { params } = config
    let response = getMockSettingRecipientsReferencesID(params.id)
    if (response.result.data.length === 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([404, response])
        }, mock.delayResponse)
      })
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, response])
      }, mock.delayResponse)
    })
  })
}

export default setMockData

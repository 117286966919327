import { useDispatch } from 'react-redux'
import { removeLoading, setLoading } from '../../store/loading/loadingAction'

const useLoading = () => {
  const dispatch = useDispatch()
  const set = () => {
    dispatch(setLoading())
  }

  const remove = () => {
    dispatch(removeLoading())
  }

  return {
    set,
    remove,
  }
}

export default useLoading

import { BACKEND_TIMEZONE_UTC_MINUTES_OFFSET } from '../constants'

export const getMondayOfCurrentWeek = (d) => {
  const today = new Date(d)
  const day = today.getDay()
  const diff = today.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  return new Date(today.setDate(diff))
}

export const getSundayOfCurrentWeek = (d) => {
  const today = new Date(d)
  const first = today.getDate() - (today.getDay() > 0 ? today.getDay() : 7) + 1
  const last = first + 6
  return new Date(today.setDate(last))
}

export const transformDateForRequest = (d) => {
  if (!d) {
    return undefined
  }
  const years = d.getFullYear()
  const month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  const date = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
  return `${years}-${month}-${date}`
}

export const getKeyForGrid = (prefix, data = new Date(), postfix) => {
  return `${prefix}-${transformDateForRequest(new Date(data))}-${postfix}`
}

export const getDateOfLesson = (d = new Date()) => {
  const weekDay = d.getDay()
  const years = d.getFullYear()
  const month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  const monthDate = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
  const lessonDate = monthDate + '.' + month + '.' + years
  return {
    weekDay: weekDayArray[weekDay],
    lessonDate,
  }
}

export const getCurrentDateTime = () => {
  const currentDate = new Date()
  let userOffset = currentDate.getTimezoneOffset()
  userOffset -= userOffset + BACKEND_TIMEZONE_UTC_MINUTES_OFFSET
  currentDate.setTime(currentDate.getTime() - userOffset * 60000)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)
  return currentDate
}

export const getDateOfWeekDay = (d) => {
  const weekDayArray = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  return weekDayArray.map((el, index) => {
    const date = new Date(d.getFullYear(), d.getMonth(), d.getDate() + index)
    return {
      day: el,
      textDate: `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2,
      )}.${date.getFullYear()}`,
      fullDate: transformDateForRequest(date),
    }
  })
}

export const weekDayArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const getDateValue = (val) => {
  const hour = Math.trunc(val / 60)
  const minute = val % 60
  return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`
}

export const getValueFromDate = (val) => {
  const [hour, minute] = val.split(':')
  return Number(hour) * 60 + Number(minute)
}

export const getDeadLineDate = (d = new Date()) => {
  return d.getMonth() < 5 ? new Date(d.getFullYear(), 4, 31) : new Date(d.getFullYear(), 11, 31)
}

export const addDaysToDate = (date, number) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + number)
  return newDate
}

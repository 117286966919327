import {
  CREATE_PROFILE,
  GET_PROFILES,
  RESET_PROFILE_WAS_CREATED,
  UPDATE_PROFILES,
  DELETE_PROFILE,
  RESET_PROFILE_WAS_DELETE,
  GET_PROFILES_ARRAY,
  REST_PROFILES_ARRAY,
} from './profilesTypes'

const initialState = {
  profilesData: {},
  profilesGotByGroup: [],
  profileWasCreated: false,
  profileWasDeleted: false,
}

const profilesReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_PROFILES:
      return { ...prevState, [action.id]: action.data }
    case GET_PROFILES_ARRAY:
      return {
        ...prevState,
        profilesData: Object.assign(
          prevState.profilesData,
          ...action.data.map((el) => ({ [el.id]: el })),
        ),
        profilesGotByGroup: [...new Set([...prevState.profilesGotByGroup, ...action.studyGroups])],
      }
    case REST_PROFILES_ARRAY:
      return {
        ...prevState,
        profilesData: {},
        profilesGotByGroup: [],
      }
    case UPDATE_PROFILES:
      return { ...prevState }
    case CREATE_PROFILE:
      return { ...prevState, profileWasCreated: true }
    case RESET_PROFILE_WAS_CREATED:
      return { ...prevState, profileWasCreated: false }
    case DELETE_PROFILE:
      return { ...prevState, profileWasDeleted: true }
    case RESET_PROFILE_WAS_DELETE:
      return { ...prevState, profileWasDeleted: false }
    default:
      return prevState
  }
}

export default profilesReducer

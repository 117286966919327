import {
  GET_LESSONS_LIST,
  GET_JOURNAL,
  CLEAR_JOURNAL,
  SET_IS_BUSY,
  GET_FILTER_STUDY_GROUPS,
  GET_FILTER_DISCIPLINES,
  SET_ITEM_IS_UPDATING,
} from './electronicJournalTypes'

const initialState = {
  lessonsList: [],
  journal: null,
  isBusyIds: {},
  filter: {
    studyGroups: [],
    disciplines: [],
  },
  itemIsUpdating: {},
}

/**
 * Хранилище для электронного журнала
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const electronicJournalReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_LESSONS_LIST:
      return {
        ...prevState,
        lessonsList: action.data,
      }
    case GET_JOURNAL:
      return {
        ...prevState,
        journal: action.data,
      }
    case CLEAR_JOURNAL:
      return {
        ...prevState,
        journal: null,
      }
    case GET_FILTER_STUDY_GROUPS:
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          studyGroups: action.data,
        },
      }
    case GET_FILTER_DISCIPLINES:
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          disciplines: action.data,
        },
      }
    case SET_IS_BUSY:
      return {
        ...prevState,
        isBusyIds: {
          ...prevState.isBusyIds,
          [action.data.id]: action.data.state,
        },
      }
    case SET_ITEM_IS_UPDATING:
      return {
        ...prevState,
        itemIsUpdating: {
          ...prevState.itemIsUpdating,
          [action.data.id]: action.data.itemIsUpdating,
        },
      }
    default:
      return prevState
  }
}
export default electronicJournalReducer

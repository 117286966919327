export const UPDATE_PROFILES = 'UPDATE_PROFILES'
export const GET_PROFILES = 'GET_PROFILES'

export const CREATE_PROFILE = 'CREATE_PROFILE'
export const RESET_PROFILE_WAS_CREATED = 'RESET_PROFILE_WAS_CREATED'

export const DELETE_PROFILE = 'DELETE_PROFILE'
export const RESET_PROFILE_WAS_DELETE = 'RESET_PROFILE_WAS_DELETE'

export const GET_PROFILES_ARRAY = 'GET_PROFILES_ARRAY'
export const REST_PROFILES_ARRAY = 'REST_PROFILES_ARRAY'

import {
  GET_ABSENCE_REASON,
  GET_CURRENT_PROGRESS,
  GET_FILTER_STUDY_GROUPS,
  GET_FILTER_DISCIPLINES,
  RESET_ABSENCE_REASON,
  RESET_PROGRESS,
  RESET_STATE,
} from './currentProgressTypes'

const initialState = {
  progress: null,
  filter: {
    disciplines: [],
    studyGroups: [],
  },
  absenceReason: null,
}

const currentProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_PROGRESS:
      return {
        ...state,
        progress: action.data,
      }
    case GET_FILTER_STUDY_GROUPS:
      return {
        ...state,
        filter: {
          ...state.filter,
          studyGroups: action.data,
        },
      }
    case GET_FILTER_DISCIPLINES:
      return {
        ...state,
        filter: {
          ...state.filter,
          disciplines: action.data,
        },
      }
    case GET_ABSENCE_REASON:
      return {
        ...state,
        absenceReason: action.data,
      }
    case RESET_ABSENCE_REASON:
      return {
        ...state,
        absenceReason: null,
      }
    case RESET_PROGRESS:
      return {
        ...state,
        progress: null,
      }
    case RESET_STATE:
      return initialState
    default:
      return state
  }
}
export default currentProgressReducer

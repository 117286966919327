import {
  GET_ALL_EMAIL_TEMPLATE_DATA,
  GET_EMAIL_TEMPLATE_BY_ID,
  RESET_EMAIL_WAS_CREATED_OR_UPDATED,
  UPDATE_EMAIL,
} from './emailTypes'

const initialState = {
  allEmailTemplate: {},
  emailTemplate: {},
  emailTemplateUpdated: false,
}

/**
 * Хранилище для email шаблонов
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const emailReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EMAIL_TEMPLATE_DATA:
      return { ...prevState, allEmailTemplate: { ...action.data } }
    case GET_EMAIL_TEMPLATE_BY_ID:
      return {
        ...prevState,
        emailTemplate: { ...prevState.emailTemplate, [action.data.id]: action.data },
      }
    case UPDATE_EMAIL:
      return {
        ...prevState,
        emailTemplate: { ...prevState.emailTemplate, [action.data.id]: action.data },
        emailTemplateUpdated: true,
      }
    case RESET_EMAIL_WAS_CREATED_OR_UPDATED:
      return {
        ...prevState,
        emailTemplateUpdated: false,
      }
    default:
      return prevState
  }
}

export default emailReducer

import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import useToast from './hooks/useToast'
import { clearSessionToast, getSessionToast } from '../utils/sessionToast'
import AppShowLoading from './ui/AppShowLoading'
import { MetrikaRouterObserver } from 'src/services/metrika'

// Containers
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
const NotAuthorizedLayout = React.lazy(() => import('../layout/NotAuthorizedLayout'))
const DownloadOpdFile = React.lazy(() => import('../views/mgu/systems/DownloadOpdFile'))
const SetPassword = React.lazy(() => import('../views/mgu/systems/SetPassword'))
const RecoverPassword = React.lazy(() => import('../views/mgu/systems/RecoverPassword'))
const SchedulePublic = React.lazy(() => import('../views/mgu/schedule/SchedulePublic'))
const SchedulePrint = React.lazy(() => import('../views/mgu/schedule/SchedulePrintRender'))
const SchedulePrintTemplate = React.lazy(() =>
  import('../views/mgu/schedule/SchedulePrintTemplate'),
)

const AppContainer = () => {
  const { keycloak } = useAuth()
  const addToast = useToast()
  useEffect(() => {
    const toast = getSessionToast()
    if (toast?.message?.length > 0) {
      clearSessionToast()
      addToast(toast.message, { type: toast.type })
    }
    return () => {}
  }, [])

  return (
    <HashRouter>
      <MetrikaRouterObserver />
      <Suspense fallback={<AppShowLoading />}>
        <Routes>
          <Route path="/opd" element={<DownloadOpdFile path={'opd'} />} />
          <Route path="/mobile_policy" element={<DownloadOpdFile path={'mobile_policy'} />} />
          <Route
            path="how_to_get_access"
            element={<DownloadOpdFile path={'how_to_get_access'} />}
          />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="public/schedule" element={<SchedulePublic />} />
          <Route path="public/schedule-print" element={<SchedulePrint />} />
          <Route path="schedule/print/:type/:id" element={<SchedulePrintTemplate />} />
          {keycloak.authenticated ? (
            <Route path="*" element={<DefaultLayout />} />
          ) : (
            <Route path="*" element={<NotAuthorizedLayout />} />
          )}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default AppContainer

import {
  CREATE_LESSON,
  CREATE_SCHEDULE_ACADEMIC_PLANS,
  CREATE_STUDY_GROUP_SCHEDULE,
  GET_ALL_DEPARTMENT,
  GET_ALL_SCHEDULE,
  GET_ALL_SCHEDULE_ACADEMIC_PLANS,
  GET_ALL_STUDY_GROUP_SCHEDULE,
  GET_BY_ID_SCHEDULE,
  GET_BY_ID_SCHEDULE_ACADEMIC_PLANS,
  GET_BY_ID_STUDY_GROUP_SCHEDULE,
  GET_LESSON_DISCIPLINES,
  GET_LESSON_STUDY_GROUPS,
  GET_LESSON_TEACHER_USERS,
  GET_LESSON_TEACHERS,
  GET_DEPARTMENT_BY_ID,
  GET_ONLINE_PLATFORM_FOR_LESSON_CREATE,
  REMOVE_STUDY_GROUP_SCHEDULE,
  RESET_LESSON_WAS_CREATED_OR_UPDATED,
  RESET_CREATED_LESSON_ID,
  RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED,
  RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED,
  UPDATE_LESSON,
  UPDATE_SCHEDULE_ACADEMIC_PLANS,
  UPDATE_STUDY_GROUP_SCHEDULE,
  GET_ALL_SCHEDULE_WORK,
  GET_SCHEDULE_STATUS,
  CREATE_SCHEDULE,
  GET_CURRENT_SCHEDULE_WORK,
  RESET_CURRENT_SCHEDULE_WORK,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_WORK_BY_ID,
  RESET_CREATE_SCHEDULE_WAS_CREATED,
  RESET_CREATE_SCHEDULE_WAS_UPDATED,
  SET_SCHEDULE_DATE,
  RESET_LESSON_STUDY_GROUPS,
  GET_PRINT_MODAL_DATA,
  RESET_PRINT_MODAL_DATA,
  SET_PRINT_DATA,
  RESET_PRINT_DATA,
  SET_PRINT_MODAL_DATA,
  CLEAR_LESSONS_STORE,
  CLEAR_LESSON,
  DELETE_SCHEDULE,
  RESET_DELETE_SCHEDULE,
  SET_FIRST_UNREAD_LESSON,
  RESET_FIRST_UNREAD_LESSON,
  RESET_SCHEDULE_WORK,
} from './scheduleTypes'
import { transformDateForRequest } from '../../utils/dateUtils'

const initialState = {
  allSchedule: {
    result: {},
  },
  schedule: {},
  allScheduleWork: {
    result: {},
  },
  scheduleWork: {},
  allStudyGroup: {
    result: {},
  },
  studyGroups: {},
  allAcademicPlans: {
    result: [],
  },
  academicPlans: {},
  createLesson: {
    onlinePlatform: {},
  },
  allDepartment: {
    result: [],
  },
  department: {},
  scheduleStatusArr: {
    result: {},
  },
  currentSchedule: {
    result: {},
  },
  printModalData: {},
  printData: {},
  newScheduleId: 0,
  academicPlansWasCreatedOrUpdated: false,
  studyGroupWasCreatedOrUpdated: false,
  createdLessonId: null,
  scheduleWasDeleted: false,
  scheduleWasCreated: false,
  scheduleWasUpdated: false,
  printModalDataWasSet: false,
  lessonFilter: {
    disciplines: [],
    studyGroups: [],
    teacherUsers: [],
    teachers: [],
    isStudyGroupsLoading: true,
  },
  dateSchedule: {
    date_from: transformDateForRequest(new Date()),
    date_to: transformDateForRequest(new Date()),
  },
  firstUnreadLesson: {},
}
/**
 * Хранилище для расписания
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const scheduleReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SCHEDULE:
      return { ...prevState, allSchedule: { ...action.data.data } }
    case GET_ALL_STUDY_GROUP_SCHEDULE:
      return { ...prevState, allStudyGroup: { ...action.data.data } }
    case GET_ALL_SCHEDULE_ACADEMIC_PLANS:
      return { ...prevState, allAcademicPlans: { ...action.data.data } }
    case GET_BY_ID_SCHEDULE:
      return {
        ...prevState,
        schedule: {
          ...prevState.schedule,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case CLEAR_LESSON:
      delete prevState.schedule[action.data]
      return prevState
    case GET_BY_ID_STUDY_GROUP_SCHEDULE:
      return {
        ...prevState,
        studyGroups: {
          ...prevState.studyGroups,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case GET_BY_ID_SCHEDULE_ACADEMIC_PLANS:
      return {
        ...prevState,
        academicPlans: {
          ...prevState.academicPlans,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case GET_SCHEDULE_WORK_BY_ID:
      return {
        ...prevState,
        scheduleWork: {
          ...prevState.scheduleWork,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case RESET_SCHEDULE_WORK:
      return { ...prevState, scheduleWork: {} }
    case GET_ALL_SCHEDULE_WORK:
      return { ...prevState, allScheduleWork: { ...action.data.data } }
    case GET_SCHEDULE_STATUS:
      return { ...prevState, scheduleStatusArr: { ...action.data.data } }
    case GET_CURRENT_SCHEDULE_WORK:
      return { ...prevState, currentSchedule: { ...action.data.data } }
    case RESET_CURRENT_SCHEDULE_WORK:
      return { ...prevState, currentSchedule: {} }
    case CREATE_SCHEDULE_ACADEMIC_PLANS:
      return {
        ...prevState,
        academicPlansWasCreatedOrUpdated: true,
      }
    case UPDATE_SCHEDULE_ACADEMIC_PLANS:
      return {
        ...prevState,
        academicPlansWasCreatedOrUpdated: true,
      }
    case RESET_SCHEDULE_ACADEMIC_PLANS_WAS_CREATED_OR_UPDATED:
      return {
        ...prevState,
        academicPlansWasCreatedOrUpdated: false,
      }
    case GET_ONLINE_PLATFORM_FOR_LESSON_CREATE:
      return {
        ...prevState,
        createLesson: {
          ...prevState.createLesson,
          onlinePlatform: action.data.data.result,
        },
      }
    case CREATE_LESSON:
      return {
        ...prevState,
        schedule: {
          ...prevState.schedule,
          [action.data.data.result.id]: { ...action.data.data },
        },
        createdLessonId: action.data.data.result.success[0].id,
      }
    case UPDATE_LESSON:
      return {
        ...prevState,
        createdLessonId: action.data.data.result.success[0].id,
        schedule: {
          ...prevState.schedule,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case CREATE_STUDY_GROUP_SCHEDULE:
      return {
        ...prevState,
        studyGroupWasCreatedOrUpdated: true,
      }
    case UPDATE_STUDY_GROUP_SCHEDULE:
      return {
        ...prevState,
        studyGroupWasCreatedOrUpdated: true,
      }
    case REMOVE_STUDY_GROUP_SCHEDULE:
      return {
        ...prevState,
      }
    case RESET_STUDY_GROUP_SCHEDULE_WAS_CREATED_OR_UPDATED:
      return {
        ...prevState,
        studyGroupWasCreatedOrUpdated: false,
      }
    case CREATE_SCHEDULE:
      return {
        ...prevState,
        newScheduleId: action.data.data.result.id,
        scheduleWasCreated: true,
      }
    case UPDATE_SCHEDULE:
      return {
        ...prevState,
        scheduleWasUpdated: true,
      }
    case RESET_CREATE_SCHEDULE_WAS_CREATED:
      return {
        ...prevState,
        scheduleWasCreated: false,
      }
    case RESET_CREATE_SCHEDULE_WAS_UPDATED:
      return {
        ...prevState,
        scheduleWasUpdated: false,
      }
    case RESET_CREATED_LESSON_ID:
      return {
        ...prevState,
        createdLessonId: null,
      }
    case GET_LESSON_DISCIPLINES:
      return {
        ...prevState,
        lessonFilter: {
          ...prevState.lessonFilter,
          disciplines: action.data,
        },
      }
    case GET_LESSON_STUDY_GROUPS:
      return {
        ...prevState,
        lessonFilter: {
          ...prevState.lessonFilter,
          studyGroups: action.data,
          isStudyGroupsLoading: false,
        },
      }
    case GET_LESSON_TEACHER_USERS:
      return {
        ...prevState,
        lessonFilter: {
          ...prevState.lessonFilter,
          teacherUsers: action.data,
        },
      }
    case RESET_LESSON_STUDY_GROUPS:
      return {
        ...prevState,
        lessonFilter: {
          ...prevState.lessonFilter,
          studyGroups: [],
          isStudyGroupsLoading: true,
        },
      }
    case GET_LESSON_TEACHERS:
      return {
        ...prevState,
        lessonFilter: {
          ...prevState.lessonFilter,
          teachers: action.data,
        },
      }
    case GET_ALL_DEPARTMENT:
      return { ...prevState, allDepartment: { ...action.data.data } }
    case GET_DEPARTMENT_BY_ID:
      return {
        ...prevState,
        department: {
          ...prevState.department,
          [action.data.data.result.id]: { ...action.data.data },
        },
      }
    case SET_SCHEDULE_DATE:
      return {
        ...prevState,
        dateSchedule: {
          ...prevState.dateSchedule,
          date_from: action.date_from,
          date_to: action.date_to,
        },
      }
    case GET_PRINT_MODAL_DATA:
      return {
        ...prevState,
        printModalData: {
          ...action.data.data,
        },
      }
    case SET_PRINT_MODAL_DATA:
      return {
        ...prevState,
        printModalDataWasSet: true,
        printModalData: {
          ...action.data.data,
        },
      }
    case RESET_PRINT_MODAL_DATA:
      return {
        ...prevState,
        printModalDataWasSet: false,
        printModalData: {},
      }
    case SET_PRINT_DATA:
      return {
        ...prevState,
        printData: { ...prevState.printData, ...action.data },
      }
    case RESET_PRINT_DATA:
      return {
        ...prevState,
        printData: {},
      }
    case CLEAR_LESSONS_STORE:
      return {
        ...prevState,
        allSchedule: {
          result: {},
        },
      }
    case DELETE_SCHEDULE: {
      return {
        ...prevState,
        scheduleWasDeleted: true,
      }
    }
    case RESET_DELETE_SCHEDULE: {
      return {
        ...prevState,
        scheduleWasDeleted: false,
      }
    }
    case SET_FIRST_UNREAD_LESSON: {
      return {
        ...prevState,
        firstUnreadLesson: { ...prevState.firstUnreadLesson, ...action.data.data },
      }
    }
    case RESET_FIRST_UNREAD_LESSON: {
      return {
        ...prevState,
        firstUnreadLesson: {},
      }
    }
    default:
      return prevState
  }
}

export default scheduleReducer

import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

const apiMock = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
})

apiMock.getAbortController = () => {
  return new AbortController()
}

export default apiMock

export const mock = new MockAdapter(apiMock, { delayResponse: 500 })

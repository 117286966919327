import { useEffect } from 'react'
import useLoading from '../hooks/useLoading'

const AppShowLoading = () => {
  const loading = useLoading()
  useEffect(() => {
    loading.set()
    return () => {
      loading.remove()
    }
  }, [])

  return null
}

export default AppShowLoading

import { GET_EMPTY_PROFILE, GET_PROGRESS_DATA } from './progressTypes'

const initialState = {
  allProgressData: {
    main: [],
    additional: [],
    difference: [],
  },
  someProfile: {},
}

/**
 * Хранилище для успеваемости студента
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const progressReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_PROGRESS_DATA:
      if (Array.isArray(action.data)) {
        return {
          ...prevState,
          someProfile: { ...prevState.someProfile, [action.id]: action.data },
        }
      }
      const { main = [], additional = [], difference = [] } = action.data
      return {
        ...prevState,
        allProgressData: {
          main: [...main].filter((el) => el !== undefined),
          additional: [...additional].filter((el) => el !== undefined),
          difference: [...difference].filter((el) => el !== undefined),
        },
        someProfile: { ...prevState.someProfile, [action.id]: action.data },
      }
    case GET_EMPTY_PROFILE:
      return initialState
    default:
      return prevState
  }
}

export default progressReducer

import { GET_FOOTER_SETTINGS } from './pageTypes'

const initialState = {
  footer: {},
}

const pageReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_FOOTER_SETTINGS:
      return { ...prevState, footer: action.data }
    default:
      return prevState
  }
}

export default pageReducer

import {
  GET_HOME_WORK,
  GET_HOME_WORK_BY_ID,
  RESET_UPDATE_HOME_WORK_BY_ID,
  UPDATE_HOME_WORK_BY_ID,
} from './homeWorkTypes'

const initialState = {
  allHomeWork: {
    result: {},
  },
  homework: {},
  homeworkWasUpdate: false,
}
/**
 * Хранилище для домашних работ студента
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const homeWorkReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_HOME_WORK:
      return { ...prevState, allHomeWork: action.data.data }
    case GET_HOME_WORK_BY_ID:
      return {
        ...prevState,
        homework: { ...prevState.homework, [action.data.data.result.id]: action.data.data.result },
      }
    case UPDATE_HOME_WORK_BY_ID:
      return {
        ...prevState,
        homeworkWasUpdate: true,
        homework: { ...prevState.homework, [action.data.data.result.id]: action.data.data.result },
      }
    case RESET_UPDATE_HOME_WORK_BY_ID:
      return {
        ...prevState,
        homeworkWasUpdate: false,
      }
    default:
      return prevState
  }
}

export default homeWorkReducer

import api from '../../services/api'
import { setError } from '../errorReducer'
import {
  DELETE_STIPEND,
  GET_STIPEND,
  GET_STIPEND_FACULTIES,
  GET_STIPEND_GROUPS,
  GET_STIPEND_PREPARATION_TYPES,
} from './stipendTypes'

export const stipendUrl = '/stipend'
export const stipendGroupUrl = '/stipend/groups'
export const stipendFacultiesUrl = '/stipend/faculties'
export const stipendPreparationTypesUrl = '/stipend/preparation_types'

export const getStipend = (params, options = {}) => {
  return async (dispatch) => {
    return api
      .get(stipendUrl, { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_STIPEND,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}
export const getStipendGroups = (params, options = {}) => {
  return async (dispatch) => {
    return api
      .get(stipendGroupUrl, { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_STIPEND_GROUPS,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const getStipendFaculties = (params, options = {}) => {
  return async (dispatch) => {
    return api
      .get(stipendFacultiesUrl, { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_STIPEND_FACULTIES,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const getStipendPreparationTypes = (params, options = {}) => {
  return async (dispatch) => {
    return api
      .get(stipendPreparationTypesUrl, { params: { ...params }, ...options })
      .then((res) =>
        dispatch({
          type: GET_STIPEND_PREPARATION_TYPES,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const deleteStipend = (param, options = {}) => {
  return async (dispatch) => {
    return api
      .delete(stipendUrl, { data: param, ...options })
      .then((res) =>
        dispatch({
          type: DELETE_STIPEND,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

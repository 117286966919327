import {
  GET_ABSENCE_REASONS,
  GET_MISSED_LESSONS,
  GET_STUDENTS,
  GET_ABSENCE_REASON_BY_ID,
  CLEAR_ABSENCE_REASON,
} from './absenceReasonsTypes'

const initialState = {
  absenceReasons: {},
  missedLessons: {},
  students: [],
  absenceReason: null,
}

const absenceReasonsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ABSENCE_REASONS:
      return { ...prevState, absenceReasons: action.data }
    case GET_MISSED_LESSONS:
      return { ...prevState, missedLessons: action.data }
    case GET_STUDENTS:
      return { ...prevState, students: action.data }
    case GET_ABSENCE_REASON_BY_ID:
      return { ...prevState, absenceReason: action.data }
    case CLEAR_ABSENCE_REASON:
      return { ...prevState, absenceReason: null }
    default:
      return prevState
  }
}
export default absenceReasonsReducer

import { SET_ACTIVE_MENU } from './menuTypes'

const initialState = {
  active: '',
}

const menuReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return { ...prevState, active: action.link }
    default:
      return prevState
  }
}

export default menuReducer

import { GET_ALL_REFERENCE, GET_REFERENCE_BY_ID } from './referenceTypes'

const initialState = {
  allReference: { result: {} },
}

const referenceReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REFERENCE:
      return { ...prevState, allReference: action.data }
    case GET_REFERENCE_BY_ID:
      return { ...prevState, [action.data.data.result.id]: { ...action.data.data } }
    default:
      return prevState
  }
}
export default referenceReducer

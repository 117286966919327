const ADD_TOAST = 'ADD_TOAST'
const RESET_TOAST = 'RESET_TOAST'
/**
 * Хранилище для передачи всплывающего сообщения
 * @param state
 * @param action
 * @returns {null|*}
 */
const initialState = null

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      // if (state) {
      //   return [...state, action.item]
      // }
      state = action.item
      return state
    case RESET_TOAST:
      return null
    default:
      return state
  }
}

export default toastReducer
export const addToastAction = (item) => ({
  type: ADD_TOAST,
  item,
})
export const resetToast = () => ({ type: RESET_TOAST })

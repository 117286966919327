const initialState = {
  code: '',
  message: '',
  error: '',
}

const SET = 'SET_ERROR'
const RESET = 'RESET_ERROR'
/**
 * Хранилище для ошибок проекта
 * @param state
 * @param type
 * @param rest
 * @returns {(*&{code: string, message: string})|{code: string, message: string}}
 */
const errorReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET:
      return { ...state, ...rest }
    case RESET:
      return { ...initialState }
    default:
      return state
  }
}
export default errorReducer
export const setError = (code, message, error) => ({ type: SET, code, message, error })
export const resetError = () => ({ type: RESET })
